import { CreditCardTwoTone, type TwoToneColor } from "@ant-design/icons";
import { type GetProps, Result, Spin } from "antd";

type SpinProps = GetProps<typeof Spin>;

export interface WelcomeSpinProps extends Readonly<SpinProps> {
    readonly color?: TwoToneColor;
}

/** Full-page spinner shown beneath a credit card icon (rotated slightly counter-clockwise). */
export const WelcomeSpin = ({ color, ...props }: Readonly<WelcomeSpinProps>) => (
    <div style={{ overflow: "hidden" }}>
        <Spin size="large" {...props}>
            {/*
             * This container must exist because the Spin is created relative to it, and centered vertically within
             * it. Without a container, it is centered vertically at the top of the page, which means the top 50%
             * is above the top of the page and invisible!
             */}
            <div style={{ marginTop: "3em" }}></div>
        </Spin>
        <Result icon={<CreditCardTwoTone rotate={-5} twoToneColor={color} />} title="" />
    </div>
);
