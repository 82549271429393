import { Button, Result } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { usePayment } from "components/Payment/usePaymentContext";
import { usePaymentStatus } from "components/usePaymentStatusContext";
import type { StripeErrorState, StripeEvent } from "./stripeFlow.props";

interface ErrorResultProps {
    readonly state: StripeErrorState;
    readonly dispatch: React.Dispatch<StripeEvent>;
}

/** Display an error if payment fails */
export const ErrorResult = ({ state, dispatch }: ErrorResultProps) => {
    const { isPayNow } = usePayment();
    const fallbackError = `Please check ${isPayNow ? "patient's" : "your"} card details`;

    const { hideModal } = usePaymentStatus();

    const displayCloseButton = () => {
        return isPayNow ? (
            <Button key="closebutton" type="default" onClick={hideModal}>
                Close
            </Button>
        ) : null;
    };

    return (
        <Result
            status="error"
            icon={<CloseCircleOutlined />}
            title="Payment Declined"
            subTitle={state.error.message ?? fallbackError}
            extra={[
                displayCloseButton(),
                <Button type="primary" key="reset" onClick={() => dispatch({ type: "reset" })}>
                    Review Payment
                </Button>,
            ]}
        />
    );
};
