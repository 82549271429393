import { Environment } from "utils/environment";

/**
 * Runtime configuration comes from config.js, which is NOT processed by bundling and minifying, so that it can be
 * altered during deployment, which happens after the build has already produced an artifact.
 */
export type RuntimeConfig = RuntimeConfigPaymentsUI | RuntimeConfigPatientPortal;

const packages = Object.freeze(["payments-ui", "patient-portal"] as const);
type Packages = typeof packages;
type Package = Packages[number];

export interface RuntimeConfigCommon {
    pkg: Package;
    environment: string;
    version: string;
    apiUrl: string;
    payFieldsKey: string;
    stripePublishableKey: string;
}

export interface RuntimeConfigPaymentsUI extends RuntimeConfigCommon {
    pkg: Packages[0];
    ldKey: string;
    pendoKey: string;
    trellisAppUrl: string;
}

export interface RuntimeConfigPatientPortal extends RuntimeConfigCommon {
    pkg: Packages[1];
}

// config.js puts the runtime configuration on this window property
declare global {
    // eslint-disable-next-line no-var -- ESLint doesn't understand TypeScript
    var __runtime_configuration: RuntimeConfig;
}

/** Configuration provided to the system at runtime. */
export class ConfigurationCommon {
    constructor() {
        const rawConfig = window.__runtime_configuration;

        assertPackage(rawConfig.pkg);
        this.pkg = rawConfig.pkg;
        this.version = rawConfig.version;
        this.environment = new Environment(rawConfig.environment);
        this.apiUrl = rawConfig.apiUrl;
        this.payFieldsKey = rawConfig.payFieldsKey;
        this.stripePublishableKey = rawConfig.stripePublishableKey;
    }

    /** The entry point pkg name. */
    readonly pkg: Package;

    /** The application version number. */
    readonly version: string;

    /** The environment the system is running in. */
    readonly environment: Environment;

    /** The base URL to call the Payments API. */
    readonly apiUrl: string;

    /** The SDK key for PayFields. */
    readonly payFieldsKey: string;

    /** The Stripe publishable key. */
    readonly stripePublishableKey: string;
}

function assertPackage(pkg: Package) {
    if (!packages.includes(pkg)) {
        throw new Error(`invalid pkg identifier ${pkg}`);
    }
}

/** The shared system configuration data. */
const configuration = new ConfigurationCommon();

export default configuration;
