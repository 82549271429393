import { createContext } from "react";

/** Potential overall states of the payment processing. */
export type PaymentStatus = "data-entry" | "submitting" | "success" | "error";

/** Properties exposed by the payment status context. */
export interface PaymentStatusContextProps {
    /** Current status of the payment process. */
    readonly status: PaymentStatus;

    /** To close the modal via the Close button in the Take a Payment Flow. */
    readonly hideModal: () => void;

    /** Callback to update the payment process status. */
    readonly setStatus: React.Dispatch<React.SetStateAction<PaymentStatus>>;
}

const defaultContext: PaymentStatusContextProps = {
    status: "success",
    setStatus: () => {
        return;
    },
    hideModal: () => {
        /* do nothing */
    },
};

/** The context used to obtain and provide payment status information. */
export const PaymentStatusContext = createContext<PaymentStatusContextProps>(defaultContext);
