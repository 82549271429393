import "./pageContentContainers.scss";
import { Flex, type GetProps } from "antd";
import type { PropsWithChildren, ReactNode } from "react";

interface ContainerProps extends PropsWithChildren {
    readonly className?: string;
    readonly children: ReactNode;
}

export const FullPageContentContainer = ({ className, ...props }: ContainerProps) => (
    <PageContentContainer className={`navigation-page-container-full ${className ?? ""}`} {...props} />
);

export const NarrowPageContentContainer = ({ className, ...props }: ContainerProps) => (
    <PageContentContainer className={`navigation-page-container-narrow ${className ?? ""}`} {...props} />
);

const PageContentContainer = ({ className, ...props }: Readonly<GetProps<typeof Flex>>) => {
    return <Flex vertical={true} gap="middle" className={`navigation-page-container ${className}`} {...props} />;
};
