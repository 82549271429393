import { PaymentContext } from "./usePaymentContext";
import { type StrictlyDollars, dollarsFromBasisPoints } from "utils/currency";
import { useAmount } from "./Components/Amount/useAmount";
import { useMemo } from "react";
import type { PatientPrepareSaleResult } from "api/responses";
import type { PaymentProps } from ".";

/** Provide the shared Payment context information to all children. */
export const PaymentContextProvider = ({
    children,
    patientId,
    prepared,
    settings,
    isPayNow,
    token,
}: Readonly<React.PropsWithChildren<PaymentProps>>) => {
    const [amountState, dispatchAmount] = useAmount(
        getPreparedDollars(prepared),
        !!isPayNow,
        !!isPayNow && !!prepared.pmsBalances,
        !!isPayNow || settings.allowPartialPayment,
        settings.paymentProcessor,
    );

    const contextValue = useMemo(
        () => ({ patientId, prepared, settings, isPayNow, token, amountState, dispatchAmount }),
        [patientId, prepared, settings, isPayNow, token, amountState, dispatchAmount],
    );

    return <PaymentContext.Provider value={contextValue}>{children}</PaymentContext.Provider>;
};

/** Identify the initial balance amount to pay. */
function getPreparedDollars(prepared: PatientPrepareSaleResult) {
    return prepared.created ? dollarsFromBasisPoints(prepared.defaultBalance) : (NaN as StrictlyDollars);
}
