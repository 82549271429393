import { DataEntry } from "./DataEntry";
import { ErrorResult } from "./ErrorResult";
import { PostPaymentFlow } from "components/Payment/PostPaymentFlow";
import { RequiresPaymentMethodOnTerminal } from "./RequiresPaymentMethodOnTerminal";
import { WelcomeSpin } from "components/Payment/Components/WelcomeSpin";
import { invert } from "utils/currency";
import { theme } from "antd";
import { useSubmission } from "./useSubmission";
import type { StripeEvent, StripeState } from "./stripeFlow.props";

const { useToken } = theme;

export interface StripeFlowProps {
    readonly state: StripeState;
    readonly dispatch: React.Dispatch<StripeEvent>;
}

/** Display the different states of the Stripe payment flow. */
export const StripeFlow = ({ state, dispatch }: StripeFlowProps) => {
    // Need to be inside Stripe context to use it for data submission
    useSubmission(state, dispatch);

    switch (state.type) {
        case "initial":
        case "submitting": // Need to keep Payment Element mounted during submission process
        case "error":
            // Keep Payment Element mounted during error display, so that user can retry without re-entering entire card details
            // DataEntry will render as hidden using CSS when in the error state
            return (
                <>
                    {(state.type !== "error" || state.wasSubmitting) && <DataEntry state={state} dispatch={dispatch} />}
                    {state.type === "error" && <ErrorResult state={state} dispatch={dispatch} />}
                </>
            );

        case "ok":
            return (
                <PostPaymentFlow amount={invert(state.amount)} defaultReceiptContact={state.defaultReceiptContact} />
            );

        default: // confirmed, intent-polling
            return <InProgress state={state} />;
    }
};

const InProgress = ({ state }: { readonly state: StripeState }) => {
    const { token } = useToken();

    if ("intentPollingStatus" in state && state.intentPollingStatus === "requires_payment_method_on_terminal") {
        // note requires_payment_method_on_terminal is a custom Vyne status (not a Stripe native status)
        // in this scenario, we're intent-polling for a card-present scenario, waiting for
        // the P/RP to present their payment method to the terminal (reader)
        return <RequiresPaymentMethodOnTerminal state={state} />;
    }

    return <WelcomeSpin tip="Payment in progress&hellip;" color={token.colorPrimary} />;
};
