import "./payFieldsForm.scss";
import { BillingZipInput } from "./BillingZipInput";
import { captureException } from "@sentry/browser";
import { useCallback } from "react";
import { useGlobalPaymentsForm } from "../../PayFields/useGlobalPaymentsForm";
import type { GlobalPaymentsCardForm } from "../../PayFields/payFields.types";
import type { PayButtonMode } from "components/Payment/Components/payButtonMode";
import type { PayFieldsEvent } from "../payFieldsFlow.events";
import type { PayFieldsState } from "../payFieldsFlow.state";

/** PayFields form properties */
interface PayFieldsFormProps {
    /** Current PayFields state. */
    readonly state: PayFieldsState;

    /** Callback to dispatch a PayFields event. */
    readonly dispatch: React.Dispatch<PayFieldsEvent>;

    /** Text to show within submit button (and enabled state of submit button) */
    readonly payMode: PayButtonMode;
}

function useConfiguredGPIForm(dispatch: React.Dispatch<PayFieldsEvent>) {
    const prepareForm = useCallback(
        (cardForm: GlobalPaymentsCardForm) => {
            cardForm.on("submit", "click", () => dispatch({ type: "pay-clicked" }));

            cardForm.on("token-success", (gpiResponse) =>
                dispatch({
                    type: "token-success",
                    ...gpiResponse,
                }),
            );

            cardForm.on("token-error", (gpiResponse) => {
                captureException(new Error("GPI PayFields could not tokenize card data", { cause: gpiResponse }));

                dispatch({
                    type: "token-error",
                    ...gpiResponse,
                });
            });
        },
        [dispatch],
    );

    return useGlobalPaymentsForm(prepareForm);
}

/** Displays the data entry form for manual payments. */
export const PayFieldsForm = ({ state, dispatch, payMode }: PayFieldsFormProps) => {
    const fieldTestsPassed = useConfiguredGPIForm(dispatch);
    const submitDisabled = !payMode.enabled || !fieldTestsPassed;

    const className = state.cardOnFileId ? "vyne-pay-fields vyne-payment-fields-hidden" : "vyne-pay-fields";

    return (
        <section id="pay-fields" className={className}>
            <PayFieldsDataEntry state={state} dispatch={dispatch} />
            <PayFieldsSubmission submitDisabled={submitDisabled} payModeText={payMode.text} />
        </section>
    );
};

const PayFieldsDataEntry = ({ state, dispatch }: Readonly<Pick<PayFieldsFormProps, "state" | "dispatch">>) => (
    <fieldset className="payment-details-card">
        <div id="pf-card-grid" aria-labelledby="card-details-title">
            <div id="card-number-wrapper">
                <label htmlFor="card-number">Card Number</label>
                <div id="card-number"></div>
            </div>
            <div id="card-expiration-wrapper">
                <label htmlFor="card-expiration">EXP</label>
                <div id="card-expiration"></div>
            </div>
            <div id="card-cvv-wrapper">
                <label htmlFor="card-cvv">CVV</label>
                <div id="card-cvv"></div>
            </div>
            <div id="billing-zip-wrapper">
                <label htmlFor="billing-zip">Zip</label>
                <BillingZipInput state={state} dispatch={dispatch} />
            </div>
        </div>
    </fieldset>
);

interface PayFieldsSubmissionProps {
    readonly submitDisabled: boolean;
    readonly payModeText: string;
}

const PayFieldsSubmission = ({ submitDisabled, payModeText }: PayFieldsSubmissionProps) => (
    <div id="pf-submit-grid" className={submitDisabled ? "submit-disabled" : undefined}>
        <div className="retexter">
            <div id="pf-retexter-text">{payModeText}</div>
        </div>
        <div className="disabler">
            <button className="gpi-pay-button" type="button" aria-label={submitDisabled ? "Pay (disabled)" : undefined}>
                {payModeText}
            </button>
        </div>
        <div
            id="submit-wrapper"
            aria-label={!submitDisabled ? "Pay" : undefined}
            aria-describedby={!submitDisabled ? "pf-retexter-text" : undefined}
            role={!submitDisabled ? "button" : undefined}
        >
            <div id="submit"></div>
        </div>
    </div>
);
