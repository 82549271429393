import "./vyneTable.scss";
import { Empty, type GetProps, type GetRef, Table } from "antd";
import { VyneResultError } from "components/vyneResults/VyneResultError";
import { useCallback } from "react";
import type { AnyObject } from "antd/es/_util/type";

type TableProps<T extends AnyObject> = GetProps<typeof Table<T>>;
type TableRef<T extends AnyObject> = GetRef<typeof Table<T>>;

export interface VyneTableProps<T extends AnyObject>
    extends Readonly<Omit<TableProps<T>, "tableLayout" | "size" | "showSorterTooltip" | "bordered">> {
    readonly name: string;
    readonly erroring?: boolean;
    readonly errorMessage?: string;
    readonly bordered?: undefined | "none" | "partial" | "full";
}

export function VyneTable<T extends AnyObject>({
    name,
    erroring,
    errorMessage,
    bordered = "partial",
    ...props
}: Readonly<VyneTableProps<T>>) {
    const tableProps: TableProps<T> = { ...props };
    let tableClass = "vyne-internal-table";

    // Force CSS
    tableProps.className = `vyne-table ${props.className ?? ""}`;
    tableProps.tableLayout = "fixed";

    // Force Our Own Type of Bordered
    if (bordered === "partial") {
        tableClass += " vyne-internal-table-bordered";
    } else if (bordered === "full") {
        tableProps.bordered = true;
    }

    // Force Pagination
    if (tableProps.pagination !== undefined && tableProps.pagination !== false) {
        tableProps.pagination.total = tableProps.dataSource?.length ?? 0;

        const smallestPageOption = tableProps.pagination.pageSizeOptions
            ? Number(tableProps.pagination.pageSizeOptions[0])
            : tableProps.pagination.defaultPageSize;
        const showPageSizeSelector =
            smallestPageOption !== undefined && smallestPageOption < tableProps.pagination.total;

        tableProps.pagination = {
            ...tableProps.pagination,
            hideOnSinglePage: tableProps.pagination.hideOnSinglePage ?? !showPageSizeSelector,
            showSizeChanger: tableProps.pagination.showSizeChanger ?? showPageSizeSelector,
            position: ["bottomRight"],
            showTotal: (total: number) => (total !== 1 ? `Total ${total} Items` : `Total ${total} Item`),
        };
    }

    // Force React Node If String
    if (erroring === true) {
        tableProps.dataSource = [];
        tableProps.locale = {
            emptyText: <VyneTableErrorMessage message={errorMessage} />,
        };
    } else if (typeof tableProps.locale?.emptyText === "string") {
        tableProps.locale.emptyText = (
            <Empty
                description={tableProps.locale.emptyText.split("\n").map((x, i) => (
                    // eslint-disable-next-line @eslint-react/no-array-index-key -- empty text is always hard-coded so we're safe for now
                    <p key={`Empty-Line-${i}`}>{x}</p>
                ))}
            />
        );
    }

    // Force Attributes Onto Headers
    if (tableProps.columns) {
        tableProps.columns = tableProps.columns.map((column) => {
            const columnProps = {
                ...column,
            };

            columnProps.onCell = (data, index) => {
                const onCell = column.onCell ? column.onCell(data, index) : {};
                const headers = column.title?.toString();
                let rowKey = "key";

                switch (typeof tableProps.rowKey) {
                    case "function":
                        rowKey = tableProps.rowKey(data).toString();
                        break;
                    case "string":
                        rowKey = tableProps.rowKey;
                        break;
                    case "number":
                        rowKey = tableProps.rowKey.toString();
                }

                return {
                    ...onCell,
                    "aria-label": `${data[rowKey] ?? rowKey}-${headers}`,
                };
            };

            return columnProps;
        });
    }

    // Force Adds in Accessibility Caption and Class
    // Adding both Caption and Aria Label due to Aria Label having issue with some browsers and auto translate
    const tableRef = useCallback(
        (node: null | TableRef<T>) => {
            if (
                node !== null &&
                node.nativeElement.querySelector(".ant-table-content > table.vyne-internal-table")?.ariaLabel !== name
            ) {
                const tableSelector = node.nativeElement.querySelector(".ant-table-content > table");
                tableSelector?.setAttribute("class", `${tableSelector.getAttribute("class") ?? ""} ${tableClass}`);

                const captionSelector = node.nativeElement.querySelector(".ant-table-content > table > caption");
                if (captionSelector === null) {
                    tableSelector?.prepend(VyneTableCaption(name));

                    const summaryCaptionSelector = node.nativeElement.querySelectorAll(".ant-table-summary > tr");
                    if (summaryCaptionSelector.length > 0) {
                        summaryCaptionSelector.forEach((element, key) =>
                            element.setAttribute("aria-label", `${name} Summary Row ${key + 1}`),
                        );
                    }
                }
            }
        },
        [tableClass, name],
    );

    return <Table {...tableProps} aria-label={name} ref={tableRef} size="middle" showSorterTooltip={false} />;
}

const VyneTableCaption = (name: string) => {
    const caption = document.createElement("caption");

    caption.innerHTML = name;
    caption.setAttribute("class", "aria-invisible");

    return caption;
};

const VyneTableErrorMessage = ({ message }: { readonly message?: string }) => {
    const errorText = <p>{message ?? "There was a problem retrieving table data."}</p>;

    return <VyneResultError status="error" title="Unable To Load Table Data" subTitle={errorText} />;
};
