import { createContext, useContext } from "react";
import type { AmountAction, AmountState } from "./Components/Amount/useAmount";
import type { PaymentProps } from ".";

export interface PaymentContextProps extends PaymentProps {
    /** The state of the payment amount. */
    readonly amountState: AmountState;

    /** The callback that can make changes to the payment amount. */
    readonly dispatchAmount: React.Dispatch<AmountAction>;
}

export const PaymentContext = createContext<PaymentContextProps | undefined>(undefined);

/** Obtain the shared Payment context information. */
export function usePayment() {
    const payment = useContext(PaymentContext);
    if (!payment) throw new Error("Payment context is required");
    return payment;
}
