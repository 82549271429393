import "./vyneResultError.scss";
import { Alert, type GetProps, Result, Space } from "antd";

const SubTitle = ({ children }: Readonly<React.PropsWithChildren>) => (
    <Space direction="vertical">
        {children}
        <span>Contact support if this error persists.</span>
    </Space>
);

export const VyneResultError = ({ subTitle, ...props }: Readonly<GetProps<typeof Result>>) => (
    <Result subTitle={<SubTitle>{subTitle}</SubTitle>} {...props} />
);

export const VyneAlertError = ({ description, className, ...props }: Readonly<GetProps<typeof Alert>>) => (
    <Alert
        className={`vyne-alert-error ${className ?? ""}`}
        description={<SubTitle>{description}</SubTitle>}
        {...props}
    />
);
