import { DataEntry } from "./DataEntry";
import { ErrorResult } from "./ErrorResult";
import { PostPaymentFlow } from "components/Payment/PostPaymentFlow";
import { WelcomeSpin } from "components/Payment/Components/WelcomeSpin";
import { theme } from "antd";
import { useSubmission } from "./useSubmission";
import type { PatientSaleResponse } from "api/responses";
import type { PayFieldsEvent } from "./payFieldsFlow.events";
import type { PayFieldsState } from "./payFieldsFlow.state";
import type { SaleMutator } from "api/clients/useSale";

const { useToken } = theme;

interface PayFieldsFlowProps {
    readonly state: PayFieldsState;
    readonly dispatch: React.Dispatch<PayFieldsEvent>;
    readonly saleData?: PatientSaleResponse;
    readonly mutate: SaleMutator;
}

/** Display the different states of the GPI payment flow. */
export const PayFieldsFlow = ({ state, dispatch, saleData, mutate }: PayFieldsFlowProps) => {
    const { token } = useToken();
    useSubmission(state, dispatch, mutate);

    switch (state.type) {
        case "initial":
        case "tokenizing": // Need to keep GPI form mounted during tokenizing process
            return <DataEntry state={state} dispatch={dispatch} />;

        case "done":
            return (
                <PostPaymentFlow amount={saleData?.amount} detail={saleData?.processorResponse ?? saleData?.status} />
            );

        case "error":
            return <ErrorResult state={state} dispatch={dispatch} />;

        default: // tokenized
            return <WelcomeSpin tip="Payment in progress&hellip;" color={token.colorPrimary} />;
    }
};
