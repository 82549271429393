import { api } from "..";
import { getPrefetchOptions } from "utils/getPrefetchOptions";
import { useQuery } from "@tanstack/react-query";

/**
 * Client hook to get cards on file for a patient.
 * @param patientId Id of the patient responsible for payment.
 * @param prefetch True indicates that caller is pre-fetching cards, and has no intention of using the result.
 * @returns React Query properties.
 */
export function useGetPatientCards(patientId: number, prefetch?: boolean) {
    return useQuery({
        ...getPrefetchOptions(prefetch),
        staleTime: 20000,
        queryKey: ["getPatientCards", patientId],
        queryFn: async (context) => {
            const patientCardData = await api.patient.getCards(patientId, context.signal);

            // show expired cards last
            const sortedCards = patientCardData.sort((a, b) => {
                if (a.isExpired && !b.isExpired) {
                    return 1;
                } else {
                    return -1;
                }
            });

            return sortedCards;
        },
    });
}
