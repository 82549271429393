import { Form, Input } from "antd";
import type { PayFieldsEvent } from "../payFieldsFlow.events";
import type { PayFieldsState } from "../payFieldsFlow.state";

/** Billing Zip input properties */
export interface BillingZipInputProps {
    readonly state: PayFieldsState;
    readonly dispatch: React.Dispatch<PayFieldsEvent>;
}

/** Billing Zip input field shown alongside the PayFields */
export const BillingZipInput = ({ state, dispatch }: BillingZipInputProps) => {
    const value = "data" in state ? state.data.billingZip : "";

    return (
        <Form.Item>
            <Input
                id="billing-zip"
                value={value}
                onChange={(e) => dispatch({ type: "set-fields", data: { billingZip: e.target.value } })}
            />
        </Form.Item>
    );
};
