import "./vyneModal.scss";
import { type GetProps, Modal } from "antd";
import { VyneANTDThemeProvider } from "components/vyneTheme/VyneThemeProvider";

type ModalProps = GetProps<typeof Modal>;
export type VyneModalProps = ModalProps;

export const VyneModal = ({ children, style, ...props }: Readonly<React.PropsWithChildren<ModalProps>>) => {
    const modalProps: ModalProps = { ...props };

    // Force CSS
    modalProps.className = `vyne-modal ${props.className ?? ""}`;

    // Force Render of Content Container with Config Provider for CSS
    // This Is In Case It Renders Outside of Our Default Provider
    modalProps.modalRender = (children: Readonly<React.ReactNode>) => {
        return <VyneANTDThemeProvider>{children}</VyneANTDThemeProvider>;
    };

    // Force Container Of Modal to be body unless set by user
    modalProps.getContainer = modalProps.getContainer ?? document.body;

    return (
        <Modal style={{ ...style }} {...modalProps}>
            {children}
        </Modal>
    );
};
