import { Flex, Spin } from "antd";
import { FormItemGroup } from "components/vyneForm/items/VyneFormItems";
import { PayFieldsForm } from "./PayFieldsForm/PayFieldsForm";
import { SharedAmountControls } from "components/Payment/Components/Amount";
import { SharedSavedCardControls } from "components/Payment/Components/SavedCard";
import { stringFromDollars } from "utils/currency";
import { usePayFieldsWorkaround } from "./usePayFieldsWorkaround";
import { usePayment } from "components/Payment/usePaymentContext";
import type { PayFieldsEvent } from "./payFieldsFlow.events";
import type { PayFieldsState } from "./payFieldsFlow.state";

interface DataEntryProps {
    readonly state: PayFieldsState;
    readonly dispatch: React.Dispatch<PayFieldsEvent>;
}

/** The data entry form to prepare a GPI payment. */
export const DataEntry = ({ state, dispatch }: DataEntryProps) => {
    const newCardPayMode = usePayMode(state, false);
    const savedCardPayMode = usePayMode(state, true);
    const key = usePayFieldsWorkaround(state);

    return (
        <Spin spinning={state.type !== "initial"} tip="Submitting payment...">
            <FormItemGroup title="Payment Details">
                <Flex vertical={true} gap="1rem">
                    <SharedAmountControls />
                    <div>
                        <SharedSavedCardControls payMode={savedCardPayMode} state={state} dispatch={dispatch} />
                        <PayFieldsForm key={key} state={state} dispatch={dispatch} payMode={newCardPayMode} />
                    </div>
                </Flex>
            </FormItemGroup>
        </Spin>
    );
};

/** Get the payment button text and enablement */
function usePayMode(state: PayFieldsState, savedCard: boolean) {
    const { amountState } = usePayment();

    // Make sure to disable submission when Billing Zip is empty, unless we're using a saved card
    const enabled = !!amountState.validatedAmount && (savedCard || ("data" in state && !!state.data.billingZip));
    const text = enabled ? `Pay ${stringFromDollars(amountState.validatedAmount)}` : "Pay";

    return { text, enabled };
}
