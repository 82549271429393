import "./PageHeader.scss";
import { Layout, Skeleton, Typography } from "antd";
import type { PatientLoginResponse } from "api/responses";

const { Header } = Layout;
const { Title } = Typography;

interface PracticeLogoProps {
    readonly isPending: boolean;
    readonly data: PatientLoginResponse | undefined;
}

export const PageHeader = ({ isPending, data }: PracticeLogoProps) => {
    const practiceLogo = !!data && data.customerLogoUrl;

    return (
        <Header className="page-header">
            {isPending || !data ? (
                <Skeleton.Button active style={{ width: 200, height: 59 }} />
            ) : practiceLogo ? (
                <img src={practiceLogo} alt={`${data.customerName} logo`} />
            ) : (
                <Title>{data.customerName}</Title>
            )}
        </Header>
    );
};
